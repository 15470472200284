import React from 'react'
// import React from 'react'
// import { CopBuy, CopBuyAuth } from '@tencent/cop-buy-sdk'
// import { CopBuy, CopBuyAuth } from 'assets/index.umd.js'
// import { CopBuy, CopBuyAuth } from '@tencent/cop-buy-sdk/dist/index.mjs'
import { CopBuy, CopBuyAuth } from '@tencent/cop-buy-sdk/dist/index.umd.js'
import store from '@store'
import { SAAS_HOST } from '@configs'
import './style.less'
import { message } from 'antd'

interface Props {
  customerUin?: string;
  productList?: any;
  copId?: number;
  reload?: Function;
  fetchValidSelectionList?: Function;
  saleTags?: string[];
}

const Demo: React.FC<Props> = (props) => {
  const { customerUin, productList, copId, fetchValidSelectionList, saleTags } = props
  const { userInfo } = store.useSession()

  const copBuyAuthProps = {
    appId: `TCC#${userInfo?.belongModule}`,
    operator: userInfo?.openid,
    operatorAdmin: userInfo?.openid,
    priceQueryUin: customerUin,
    requestUrl: SAAS_HOST,
  }

  const copBuyProps = {
    requestUrl: SAAS_HOST,
    spuCode: productList?.value,
    spuName: productList?.label,
    hiddenHeader: true,
    copId,
    confirmText: '确认配置',
    theme: 'tea',
    scene: 'cop::tcc',
    // saleTags: { sale_channel: 'telemarket' },
    // saleTags: { sale_channel: 'tele_cross_channel' },
    // saleTags: { sale_channel: 'tele_cross_direct' },
    saleTags,
    onSave: () => {
      // console.log('onSave------------', JSON.stringify(saveInfo))
    },
    onCreateOrder: () => {
      // console.log('onCreateOrder------------', JSON.stringify(createOrderInfo))
      message.success('配置单生成成功，已更新列表')
      fetchValidSelectionList()
    },
    onError: () => {},
  }

  return (
    <CopBuyAuth {...copBuyAuthProps}>
      <CopBuy {...copBuyProps} className="cop-buy-body" />
    </CopBuyAuth>
  )
}

export default Demo
