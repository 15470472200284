/**
 * 配置
 * @author jasonelchen
 */
// export * from './module-list'
export const apiHostMap = {
  // '127.0.0.1': 'http://dev.api.tcc.tencent.com',
  'local.tcc.tencent.com': 'http://dev.api.tcc.tencent.com',
  'test.tcc.tencent.com': 'http://dev.api.tcc.tencent.com',
  'dev.tcc.tencent.com': 'http://dev.api.tcc.tencent.com',
  'pre.tcc.tencent.com': 'http://pre.api.tcc.tencent.com',
  'tcc.tencent.com': 'https://api.tcc.tencent.com',
  'bak.tcc.tencent.com': 'https://api.tcc.tencent.com',
  'tcc-web-7gwart8i0811324b-1258344699.tcloudbaseapp.com': 'https://api.tcc.tencent.com',
} as const

export const saasConfigHostMap = {
  'local.tcc.tencent.com': 'http://dev.xtcc.cloud.tencent.com/billing_selection',
  'test.tcc.tencent.com': 'http://dev.xtcc.cloud.tencent.com/billing_selection',
  'dev.tcc.tencent.com': 'http://dev.xtcc.cloud.tencent.com/billing_selection',
  'pre.tcc.tencent.com': 'http://pre.xtcc.cloud.tencent.com/billing_selection',
  'tcc.tencent.com': 'https://xtcc.cloud.tencent.com/billing_selection',
  'tcc-web-7gwart8i0811324b-1258344699.tcloudbaseapp.com': 'https://xtcc.cloud.tencent.com/billing_selection',
} as const

// 请求 HOST
export const API_HOST = apiHostMap[window.location.hostname] || '/'
export const SAAS_HOST = saasConfigHostMap[window.location.hostname] || '/'

// CSRF TOKEN
export const CSRF_COOKIE_NAME = 'csrfToken'
export const CSRF_HEADER_NAME = 'x-csrf-token'

export const ORIGIN_TCC_TOKEN = 'origin-tcc-token'

// 401 不跳转白名单
export const unauthorizedAPI = [
  'GetUserInfo',
  'tcc.company.getCompanyList',
  'tcc.auth.getUserInfoNotLogin',
  'tcc.company.GetModuleCompanyMap',
]
// 404 以下接口特定code的时候跳转到404页面
export const notFoundAPI = [
  'GetCustomerInfo',
]

// sentry dsn配置
export const sentryDSN = 'https://ded63301a89240ce9cacf368554b3c82@report.url.cn/sentry/2516'

// COS配置
export const CosConfig = {
  Bucket: 'tccupload-prod-1258344699', /* 必须字段 */
  Region: 'ap-guangzhou', /* 存储桶所在地域，必须字段 */
  StorageClass: 'STANDARD',
} as const

// 特定模块id，业务上有只有某某模块才会显示的逻辑
export const moduleId = {
  /* 渠道模块id */
  REBATE_ID: 5,
  /* 中长尾电销id */
  MID_LONG_TAIL_ID: 7,
  /* 中长尾清洗id */
  MID_LONG_TAIL_CLEAN_ID: 8,
  /* 腾讯会议id */
  TENCENT_MEETING_ID: 10,
  /* coc用研11 */
  COC_STUDY_ID: 11,
  /* coc转化12 */
  COC_TURN_ID: 12,
  /* sass id 13 */
  SASS_ID: 13,
}

// 产品树有效性状态过滤参数
export const ProductStatus = {
  BOTH: 0,
  VALID: 1,
  IN_VALID: 2,
}

/**
 * 返回码，按特定集合分类，每种集合对应不同的逻辑，未在集合的则reject到业务层
 */
export const codeList = {
  // 正常
  ok: [0],
  // 根据另外配置的白名单决定，是否重定向至登录页
  redirectToLogin: [401, 1009],
  // 弹窗提示并结束。不会到业务层（永远pending）
  alert: [
    601009, // 该员工目前名下有 ${customerNum} 个客户，请前往客户管理模块释放或重新分配给其他人,
    601010, // 该员工目前被以下规则 ${ruleName} 设置为客户/线索接收人，请前往规则管理页面修改,
    602007, // 该群组目前被以下规则 ${ruleName} 设置为客户/线索接收组，请前往规则管理页面修改,
    602008, // 该群组目前设为以下群组 ${groupName} 的上级群组，请先修改,
  ],
  // notFoundAPI中的接口返回下列状态码会转到404
  notFound: [
    603003, // 无权限访问该接口
    606015, // 查找客户不存在
  ],
}
